import React from 'react'
import Layout from "../components/layout/index"
import Seo from "../components/seo"
import { SteamComponent } from '../components/steam/index'

const STEAME = () => {
  return (
    <Layout>
      <Seo title="Steam" />
      <SteamComponent/>
    </Layout>
  )
}

export default STEAME