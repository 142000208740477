import React from 'react'
import * as style from './steam.module.css'
import background_image from '../../images/steam-bg.png'
import Button from '../buttons/overview_button/index'
import { CategoryLayout } from '../category_layout'

export const SteamComponent = () => {
  const steam_url = `https://steam.angazaelimu.com/signup`

  return (
    <CategoryLayout image={background_image}>
        <div className={style.details}>
            <h1>Innovators<span> STEAME</span></h1>
            <p>Personalised STEAME (Science, Technology, Engineering, Art, Math and Entrepreneurship) training to help you launch your career and business.</p>
            <p>Top courses: <span>Internet of Things, Robotics, Software Development, sales and marketting.</span></p>
            <a href={steam_url}>
              <Button text='Start Learning' link={steam_url}/>
            </a>
        </div>
    </CategoryLayout>
  )
}
