import React from "react"
// import * as style from "./category.module.css"

export const CategoryLayout = ({ image, children, className }) => {
  return (
    <div
      className={`flex flex-col md:flex-row items-center justify-center py-16 px-5 ${className}`}
    >
      <div className="flex-1">{children}</div>
      <div className="flex-[1.3]">
        <img src={image} alt="decor" />
      </div>
    </div>
  )
}
